/** @jsx jsx */
import AccountContent from 'components/AccountContent'
import {jsx} from 'theme-ui'
import {PageContext} from 'gatsbyTypes'
import {PageProps} from 'gatsby'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'

type Props = {}

const AccountPage: React.FC<PageProps<object, PageContext>> = ({
  pageContext,
}) => {
  useAutoSelectLanguage(pageContext)

  return (
    <AccountContent
      title="Votre compte"
      errorTitle="Une erreur est survenue"
      errorText="We had an error while fetching the donations data, we will try to fix
      this problem. Please try again later or contact us if it persists."
      emptyText="Bienvenue, vous n'avez pas encore effectué de don ? Faites un premier don pour voir quelque chose ici."
      WelcomeMessage={({firstName, lastName, email}) => (
        <span>
          Bienvenue {firstName} {lastName}, votre adresse courriel est: {email}.
        </span>
      )}
      signOutText="Se Déconnecter"
      donationsTitle="Votre historique de Dons"
      totalText="Vous avez donné pour un total de"
      donationsTabTitle="Historiques de dons"
      subscriptionsTabTitle="Gérez vos paiements récurrents"
    />
  )
}

export default AccountPage
